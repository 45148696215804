import styled from "styled-components";
import { insetShadow } from "./StyledComponentGenerators";

const ResponsiveTable = styled.div`
  overflow-x: auto;
  @media (max-width: 748px) {
    ${insetShadow('right')}
    margin: -24px;
    padding: 24px;
  }
`

export default ResponsiveTable