type PositionT = 'top' | 'right' | 'left' | 'bottom'
const _insetShadowDeg = (position: PositionT) => {
  switch (position) {
    case 'top':
      return '0'
    case 'bottom':
      return '180'
    case 'left':
      return '90'
    case 'right':
    default:
      return '-90'
  }
}
export const insetShadow = (position: PositionT) => `&:after {
  content: "";
  position: absolute;
  top: ${position === 'bottom' ? 'initial' : '0'};
  bottom: ${position === 'top' ? 'initial' : '0'};
  right: ${position === 'left' ? 'initial' : '0'};
  left: ${position === 'right' ? 'initial' : '0'};
  ${(position === 'right' || position === 'left') ? 'width' : 'height'}: 8px;
  background: linear-gradient(${_insetShadowDeg(position)}deg, rgba(0,0,0,.4), transparent);
}`