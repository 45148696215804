import * as React from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import { Typography, Card } from '@material-ui/core'
import JobSidebar from '../../components/jobSidebar'
import SEO from '../../components/seo/seo'
import { ChargesTable, ChangePasswordCard, EditCard, EmailSubscriptionCard, UserDetailCard, MonthlyChargesTable } from '../../components/settings'
import ContractorSidebar from '../../components/contractorSidebar'
import { useGetUser, useGetPaymentMethod } from '../../apollo/queries'
import { UserRole } from '../../apollo/types'
import { TransparentCard } from '../../components/ui/TransparentCard'
import ErrorBoundary from '../../components/ui/ErrorBoundary'
import StaticDrawerWrapper from '../../components/ui/sidebar/staticDrawerWrapper'
import IconCard from '../../components/ui/IconCard'
import ResponsiveTable from '../../components/ui/ResponsiveTable'
import Loading from '../../components/ui/Loading';

const SettingsPage = () => {

	const user = useGetUser();
	const paymentMethod = useGetPaymentMethod();

	if (!user.loading && (!user.data || !user.data.user || !user.data.user.id)) {
		navigate('/');
		return null;
	}

	let inner;
	if (user.loading || paymentMethod.loading) {
		inner = (
			<Outer>
				<Loading />
			</Outer>
		);
	} else if (user.error || paymentMethod.error) {
		inner = <div>Error: {(user.error || paymentMethod.error).message}</div>
	} else {
		console.log("userdata",user.data);
		const paymentMethodId = paymentMethod.data && paymentMethod.data.paymentMethod && paymentMethod.data.paymentMethod.id;
		inner = (
			<>
				<ErrorBoundary>
					<UserDetailCard user={user.data.user}/>
				</ErrorBoundary>
				<ErrorBoundary>
					<EditPaymentCard>
						<EditCard
							userId={user.data.user.id}
							email={user.data.user.email}
							key={paymentMethodId || "create"}
							paymentMethodId={paymentMethodId}
							initialState={paymentMethodId ? paymentMethod.data.paymentMethod : undefined}
						/>
					</EditPaymentCard>
				</ErrorBoundary>
				<StyledCard>
					{user.data.user.billedMonthly ? <MonthlyChargesTable /> : (
						<>
							<Typography variant="h6">Billing History</Typography>
							<ErrorBoundary>
								<ResponsiveTable>
									<ChargesTable />
								</ResponsiveTable>
							</ErrorBoundary>
						</>
					)}
				</StyledCard>
				<ErrorBoundary>
					<ChangePasswordCard email={user.data.user.email} />
				</ErrorBoundary>
				<ErrorBoundary>
					<EmailSubscriptionCard />
				</ErrorBoundary>
			</>
		);
	}

	const rightPanel = (
		<>
			<SEO title="User Settings" keywords={['3d', 'construction']} />
			<Scroller>
				<MainDiv>
					{inner}
				</MainDiv>
			</Scroller>
		</>
	);

	const role = user.data && user.data.user && user.data.user.role;
	if (role === UserRole.HOMEOWNER) {
		return <JobSidebar>{rightPanel}</JobSidebar>;
	} else if (role === UserRole.CONTRACTOR) {
		return <ContractorSidebar>{rightPanel}</ContractorSidebar>;
	} else {
		return <StaticDrawerWrapper>{rightPanel}</StaticDrawerWrapper>;
	}
}

export default SettingsPage;

const Scroller = styled.div`
	overflow: auto;
	height: 100%
`;

const MainDiv = styled.div`
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	overflow: auto;
`;

const StyledCard = styled(TransparentCard)`
	display: block;
	min-height: 50px;
	position: relative;
	&& {
		margin: 12px 24px;
		padding: 24px;
			overflow-y: auto;
	}
`

const Outer = styled.div`
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: center;
	height: 100%;
`

const EditPaymentCard = styled(Card)`
	margin: 24px 24px 12px 24px;
`
